<template>
  <div class="school">
    <app-header :title="title"></app-header>
    <div v-if="list.length > 0" class="school-list">
      <div v-for="item in list" :key="item.id" style="display: inline-block">
        <div class="school-item-box">
          <div class="school-item-img-box">
            <img class="school-item-img" :src="item.imageUrl || schoolImg" />
          </div>
          <div class="school-item-text">
            <div class="school-item-name">{{ item.name }}</div>
            <div style="font-size: 12px">
              房源：{{ item.roomCount || 0 }}间
            </div>
          </div>
          <div class="school-item-btn">
            <span class="edit-btn" @click="editFun(item)">修改图片</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; color: rgb(144, 147, 153); font-size: 14px;padding-top: 200px;">暂无数据</div>
    <div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
        <div>
          <el-form
            ref="myForm"
            :model="myForm"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="学校图片" prop="pictureUrlList">
              <div class="picCont">
                <div class="pic-lt">
                  <el-upload
                    class="avatar-uploader"
                    accept="image/*"
                    list-type="picture-card"
                    :action="UPLOAD_URL + '/user-service/weChat/uploadPic'"
                    :file-list="myForm.pictureUrlList"
                    :before-upload="onBeforeUpload"
                    :on-success="onSuccessByUploadImgOnly()"
                    :on-remove="onHandleRemove()"
                    :class="{
                      'one-upload-disabled':
                        myForm.pictureUrlList &&
                        myForm.pictureUrlList.length > 0,
                    }"
                  >
                    <i
                      v-if="
                        myForm.pictureUrlList &&
                        myForm.pictureUrlList.length == 0
                      "
                      class="el-icon-plus"
                    ></i>
                  </el-upload>
                </div>
              </div>
              <span style="color: #999; font-size: 12px; margin-top: 10px"
                >支持上传1张图片，支持png、jpg格式，不超过3M</span
              >
            </el-form-item>
          </el-form>

          <div style="text-align: center">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm()">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "学校信息",
      dialogTitle: "修改图片",
      dialogVisible: false,
      schoolImg: require('../../assets/school.png'),
      list: [],
      picUrl: this.UPLOAD_URL + "/user-service/weChat/uploadPic",
      myForm: {
        name: "",
        roomCount: "",
        pictureUrlList: [],
      },
      rules: {
        pictureUrlList: [{ required: true, message: "请上传学校图片", trigger: "change" }],
      },
    };
  },
  activated() {
    this.getList();
  },
  created() {},
  methods: {
    getList() {
      this.post("/landlord-service/school/query",{},{
          isUseResponse: true,
        }).then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.data;
            console.log(this.list);
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    editFun(row) {
      this.dialogVisible = true;
      this.myForm.pictureUrlList = [];
      this.myForm.pictureUrlList.push({
        url: row.imageUrl,
      });
      this.myForm.name = row.name;
      this.myForm.id = row.id;
    },

    submitForm() {
      let _this = this;
      _this.$refs.myForm.validate((valid) => {
        if (valid) {
          let url = "/landlord-service/school/edit";
          let dto = {
            id: _this.myForm.id,
            name: _this.myForm.name,
            imageUrl: _this.myForm.pictureUrlList[0].url,
          };
          _this.post(url, dto,{
            isUseResponse: true,
          })
            .then(function (res) {
              if (res.data.code == 0) {
                _this.dialogVisible = false;
                _this.$message({
                  showClose: true,
                  message: '操作成功！',
                  type: "success",
                });
                _this.getList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    /**
     * 上传文件之前的钩子
     */
    onBeforeUpload(file) {
      let is = ["image/png", "image/jpeg"].indexOf(file.type) != -1;
      let size = file.size / 1024 / 1024 > 3;
      console.log("上传文件文件大小", size);
      if (!is) {
        // console.log("上传文件之前的钩子", item, file, is);
        this.$message.error("只能上传png、jpg图片格式");
        return false;
      } else if (size) {
        this.$message.error("上传的图片不能超过3M");
        return false;
      } else {
        return true;
      }
    },
    //表单图片单张上传
    onSuccessByUploadImgOnly(key, flag, index) {
      return (response, file, fileList) => {
        console.log("response:", response);
        // this.myForm.pictureUrlList = response.data.picPath;
        this.myForm.pictureUrlList.push({
          url: response.data.picPath,
        });
        console.log("this.myForm:", this.myForm);
      };
    },
    // 图片删除
    onHandleRemove(key, flag, index) {
      return (file, fileList) => {
        this.myForm.pictureUrlList = fileList;
        this.$forceUpdate();
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.school-list{
  height: calc(100vh - 235px);
  overflow-y: auto ;
}
.school-item-box {
  padding: 27px;
  margin: 11px;
  width: 537px;
  height: 154px;
  background-color: #F7F7F7;
  border-radius: 12px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.school-item-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.school-item-btn {
  display: inline-block;
}
.school-item-img {
  width: 114px;
  height: 114px;
  border-radius: 50%;
}
.school-item-text {
  display: inline-block;
  color: #333333;
}
.school-item-img-box {
  padding-right: 18px;
}
.school-item-name {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 6px;
  width: 284px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.school-item-btn {
  position: relative;
  bottom: -40px;
  font-size: 13px;
  .edit-btn {
    color: #30bac1;
    cursor: pointer;
  }
}
</style>