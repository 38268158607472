var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "school" },
    [
      _c("app-header", { attrs: { title: _vm.title } }),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "school-list" },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                { key: item.id, staticStyle: { display: "inline-block" } },
                [
                  _c("div", { staticClass: "school-item-box" }, [
                    _c("div", { staticClass: "school-item-img-box" }, [
                      _c("img", {
                        staticClass: "school-item-img",
                        attrs: { src: item.imageUrl || _vm.schoolImg }
                      })
                    ]),
                    _c("div", { staticClass: "school-item-text" }, [
                      _c("div", { staticClass: "school-item-name" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v(
                          "\n            房源：" +
                            _vm._s(item.roomCount || 0) +
                            "间\n          "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "school-item-btn" }, [
                      _c(
                        "span",
                        {
                          staticClass: "edit-btn",
                          on: {
                            click: function($event) {
                              _vm.editFun(item)
                            }
                          }
                        },
                        [_vm._v("修改图片")]
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                color: "rgb(144, 147, 153)",
                "font-size": "14px",
                "padding-top": "200px"
              }
            },
            [_vm._v("暂无数据")]
          ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "myForm",
                      attrs: {
                        model: _vm.myForm,
                        "label-width": "80px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "学校图片", prop: "pictureUrlList" }
                        },
                        [
                          _c("div", { staticClass: "picCont" }, [
                            _c(
                              "div",
                              { staticClass: "pic-lt" },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    class: {
                                      "one-upload-disabled":
                                        _vm.myForm.pictureUrlList &&
                                        _vm.myForm.pictureUrlList.length > 0
                                    },
                                    attrs: {
                                      accept: "image/*",
                                      "list-type": "picture-card",
                                      action:
                                        _vm.UPLOAD_URL +
                                        "/user-service/weChat/uploadPic",
                                      "file-list": _vm.myForm.pictureUrlList,
                                      "before-upload": _vm.onBeforeUpload,
                                      "on-success": _vm.onSuccessByUploadImgOnly(),
                                      "on-remove": _vm.onHandleRemove()
                                    }
                                  },
                                  [
                                    _vm.myForm.pictureUrlList &&
                                    _vm.myForm.pictureUrlList.length == 0
                                      ? _c("i", { staticClass: "el-icon-plus" })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#999",
                                "font-size": "12px",
                                "margin-top": "10px"
                              }
                            },
                            [
                              _vm._v(
                                "支持上传1张图片，支持png、jpg格式，不超过3M"
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.submitForm()
                            }
                          }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }